import React from "react"
import ImageMeta from "../ImageMeta"
import MarkdownViewer from "@components/MarkdownViewer"

const IconTextRow = ({ icon, text }) => {
  return (
    <div className="icon-text-row">
      <div className="icon-text-row__icon">
        <ImageMeta
          publicId={icon}
          cloudName="nuvolum"
          width="auto"
          responsive
          responsiveUseBreakpoints="true"
        />
      </div>
      <div className="icon-text-row__text">
        <MarkdownViewer markdown={text} />
      </div>
    </div>
  )
}

export default IconTextRow
