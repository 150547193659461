import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"
import MarkdownViewer from "@components/MarkdownViewer"
import Button from "../components/Buttons/Button"
import FeaturedQuote from "../components/FeaturedQuote/FeaturedQuote"
import IconTextRow from "../components/IconTextRow/IconTextRow"
import QuoteSlider from "../components/QuoteSlider/QuoteSlider"

const About = ({ pageContext, data }) => {
  const post = data.allUniquePagesJson.nodes[0]

  const quoteSliderData = data.quoteSlider.nodes[0].quoteSlider
  return (
    <Layout customNavButton={post.customNavButton} footerType={post.footerType}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
      />

      <div className="about-page">
        <div className="section headings">
          <div className="container medium">
            <h1 className="has-text-centered">{post.heading}</h1>
            <h2 className="has-text-centered">{post.subHeading}</h2>
            <MarkdownViewer markdown={post.blurb} />
          </div>
        </div>

        <div className="section">
          <div className="container medium">
            <FeaturedQuote
              image="SIX/DEV/quotation-marks"
              quote={post.featuredQuote.quote}
              name={post.featuredQuote.person}
            />
          </div>
        </div>

        <div className="section color-back gray">
          <div className="container medium">
            <h2 className="has-text-centered bold">
              {post.howItWorks.heading}
            </h2>
            <MarkdownViewer
              className="has-text-centered"
              markdown={post.howItWorks.blurb}
            />

            <div className="icon-text-rows">
              {post.howItWorks.list.map((item, i) => (
                <React.Fragment key={i}>
                  <IconTextRow icon={item.icon} text={item.content} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        <div className="section color-back slider-section">
          <QuoteSlider quotes={quoteSliderData} />
        </div>

        <div className="section">
          <div className="container">
            <div className="columns win-sixstars">
              <div className="column is-three-fifths">
                <MarkdownViewer markdown={post.winWithSixtar.content} />
              </div>
              <div className="column relative">
                <ImageMeta
                  publicId={post.winWithSixtar.imageId}
                  cloudName="nuvolum"
                  width="auto"
                  responsive
                  responsiveUseBreakpoints="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

About.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const aboutPageQuery = graphql`
  query aboutQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        subHeading
        blurb
        featuredQuote {
          quote
          person
        }
        howItWorks {
          heading
          blurb
          list {
            content
            icon
          }
        }
        winWithSixtar {
          content
          imageId
        }
        customNavButton {
          title
          href
          type
          hasButton
        }
        footerType
      }
    }

    quoteSlider: allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        quoteSlider {
          quote
          name
          imageId
        }
      }
    }
  }
`

export default About
